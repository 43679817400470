import React from "react"
import PropTypes from "prop-types"

import BoutonSauvegarder from "../boutons/BoutonSauvegarder"
import Lien from "../navigation/Lien"
import { masquerElementParId } from "../../js/client-es/utils"

export default function SauvegardeImage({ liensTelechargement }) {
  return (
    <div id="galerie-lightbox-conteneur-sauvegarder">
      <BoutonSauvegarder
        classeCSS="galerie-lightbox-bouton-sauvegarder"
        fonctionADeclencherSurClick={cacherLiensSauvegarde}
      />
      <div
        id="galerie-lightbox-texte-tailles-sauvegarde"
        style={{ display: "none" }}
      >
        {construireLiensSauvegarde(liensTelechargement)}
      </div>
    </div>
  )
}

function construireLiensSauvegarde(liensTelechargement) {
  //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/for...in
  let liens = []
  let i = 0
  let prefixe = ""
  for (const lien in liensTelechargement) {
    const texteLien = prefixe + lien.replace("_", "")
    ++i
    liens.push(
      <span key={i}>
        {i === 1 ? "" : " | "}
        <Lien urlExterne={liensTelechargement[lien]} key={i} id={texteLien}>
          {texteLien}
        </Lien>
      </span>
    )
  }
  return liens
}

function cacherLiensSauvegarde() {
  masquerElementParId("galerie-lightbox-texte-tailles-sauvegarde")
}

SauvegardeImage.propTypes = {
  liensTelechargement: PropTypes.object,
}
