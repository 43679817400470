import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import BoutonFermer from "../boutons/BoutonFermer"
import Image from "../image/Image"
import PartageReseauxSociaux from "../navigation/PartageReseauxSociaux"
import SauvegardeImage from "../image/SauvegardeImage"
import SEO from "./SEO"

import {
  objetEstDefini,
  chaineNulleOuVide,
  obtenirImage,
  obtenirImages,
  obtenirPage,
  verifierSiPageAEteAppeleeEnInterne,
} from "../../js/client-es/utils"

import fragmentsImages from "../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"

import "../../css/base.css"
import "../../css/theme.css"
import "../../css/layouts/galerie-lightbox.css"
import BoutonAcheterTirageAvecPrix from "../boutique/BoutonAcheterTirageAvecPrix"

/*Sur les pages query, dont la syntaxe est différente des static queries, 
il est possible d'utiliser des variables venant du CreatePage (dans le fichier gatsby-node). Voir la doc ici :
https://www.gatsbyjs.com/docs/how-to/querying-data/page-query/*/

export const requete = graphql`
  query requeteImageLightbox($codeImage: String!, $codePage: String!) {
    imageLightBox: allImagesCompilationYaml(
      filter: { code: { eq: $codeImage } }
    ) {
      ...FragmentImageLightbox
    }
    pageLightBox: allPagesCompilationYaml(filter: { code: { eq: $codePage } }) {
      ...FragmentPageYaml
    }
  }
`

export default function LayoutGalerieLightbox(props) {
  const page = obtenirPage(props.data, "pageLightBox")
  const image = obtenirImage(
    obtenirImages(props.data, "imageLightBox"),
    props.pageContext.codeImage
  )
  const liensTelechargement = props.pageContext.liens_telechargement

  const codePageCiblePourBoutonFermer = obtenirPageCiblePourBoutonRetour(
    props,
    image[page.langue].galerie.code_page
  )

  return (
    <div id="galerie-lightbox-conteneur">
      <SEO page={page} />
      <div id="galerie-lightbox-conteneur-header-bouton-fermer-mobile">
        <BoutonFermer
          classeCSS="galerie-lightbox-bouton-fermer"
          codePageCible={codePageCiblePourBoutonFermer}
        />
      </div>
      <div id="galerie-lightbox-conteneur-image">
        <Image
          image={image}
          afficherLegende={false}
          langue={page.langue}
          creerLienVersGalerie={false}
        />
      </div>
      <div id="galerie-lightbox-conteneur-informations">
        <div id="galerie-lightbox-conteneur-bouton-fermer-pc">
          <BoutonFermer
            classeCSS="galerie-lightbox-bouton-fermer"
            codePageCible={codePageCiblePourBoutonFermer}
          />
        </div>
        <div id="galerie-lightbox-conteneur-titre">
          <h1>{image[page.langue].titre}</h1>
          <div id="galerie-lightbox-conteneur-partage">
            <PartageReseauxSociaux page={page} />
          </div>
          <SauvegardeImage liensTelechargement={liensTelechargement} />
        </div>
        <div id="galerie-lightbox-conteneur-description-exifs">
          {/*si besoin d'ajouter des balises html dans la description : <p dangerouslySetInnerHTML={{ __html: image.fr.description }} />*/}
          <p id="galerie-lightbox-texte-description">
            {image[page.langue].description}
          </p>
          <p id="galerie-lightbox-texte-exifs">{image[page.langue].exifs}</p>
        </div>
        {afficherBlocAchatTirage(image, page.langue)}
      </div>
    </div>
  )
}

function afficherBlocAchatTirage(image, langue) {
  if (
    !objetEstDefini(image[langue].tirage) ||
    chaineNulleOuVide(image[langue].tirage.code_page)
  )
    return undefined
  else
    return (
      <div id="galerie-lightbox-conteneur-achat-tirage-avec-prix">
        <BoutonAcheterTirageAvecPrix image={image} langue={langue} />
      </div>
    )
}

function obtenirPageCiblePourBoutonRetour(
  propsPageAppelee,
  codePageRetourSiAccesExterne
) {
  if (verifierSiPageAEteAppeleeEnInterne(propsPageAppelee)) {
    /*pour faire un history back (voir le comportement du composent "BoutonFerer*/
    return ""
  } else {
    /*sinon si appel extérieur, afficher la liste des images de la galerie*/
    return codePageRetourSiAccesExterne
  }
}

LayoutGalerieLightbox.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
  data: PropTypes.object,
}
